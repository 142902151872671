import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "about-ts-terraform",
      "style": {
        "position": "relative"
      }
    }}>{`About ts-terraform`}</h1>
    <p><inlineCode parentName="p">{`ts-terraform`}</inlineCode>{` is a suite of TypeScript modules for interacting with Terraform`}</p>
    <p><strong parentName="p">{`Status:`}</strong>{` Alpha`}</p>
    <h2 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}>{`Getting Started`}</h2>
    <p>{`You can either install `}<inlineCode parentName="p">{`ts-terraform`}</inlineCode>{`, which contains and re-exports all other modules, or just install the modules you are interested in:`}</p>
    <pre {...{
      "className": "shiki"
    }}><div parentName="pre" {...{
        "className": "language-id"
      }}>{`shell`}</div><div parentName="pre" {...{
        "className": "code-container"
      }}><code parentName="div"><span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`$ yarn add ts-terraform`}</span>{`

`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`$ yarn add @ts-terraform/hcl`}</span>{`
`}<span parentName="code" {...{
            "style": {
              "color": "#D1D5DA"
            }
          }}>{`$ yarn add @ts-terraform/provider`}</span></code></div></pre>
    <h2 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ts-terraform`}</inlineCode>{` - root package that contains all other packages`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@ts-terraform/hcl`}</inlineCode>{` - HCL2 parser, powered by Wasm`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`@ts-terraform/provider`}</inlineCode>{` - Terraform Provider gRPC client`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      